import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import "./main.scss";

export const CustomInput = (props) => {
  const { register, errors } = useFormContext();
  const { t } = useTranslation();

  let registerOptions = {};
  if (props.required) {
    registerOptions.required = t("validationRequired", {
      fieldName: t(props.label),
    });
  }

  registerOptions.validate = (value) => {
    if (!props.required && !value) return true;

    return value && value.trim()
      ? true
      : t("validationNotBlank", { fieldName: t(props.label) });
  };

  if (props.maxLength) {
    registerOptions.maxLength = {
      value: props.maxLength,
      message: t("validationMaxLength", { maxLength: props.maxLength }),
    };
  }
  if (props.regexp) {
    registerOptions.pattern = {
      value: props.regexp,
      message: t("validationInvalidCharactersInName"),
    };
  }

  if (props.validate) {
    registerOptions.validate = (value) => {
      const errorMessageKey = props.validate(value);
      if (errorMessageKey) {
        return t(errorMessageKey);
      }
      return true;
    };
  }

  return (
    <div className={`wrapper-input ${props.wrapperClass} ${props.id}`}>
      <label htmlFor={props.id}>{t(props.label)}</label>
      <div className="input-container">
        <input
          ref={register(registerOptions)}
          id={props.id}
          name={props.name}
          placeholder={t(props.placeHolder)}
          type={props.type}
          className={
            "input-custom " +
            props.class +
            (errors[props.name]?.message ? " error " : " ")
          }
          {...props.additionalAttributes}
        />
        {errors[props.name]?.ref.name && (
          <>
            <div className="error__message-block">
              <p className="error__message error__message--cardholderName">
                {errors[props.name]?.message}
              </p>
            </div>
          </>
        )}
        {props.additionalChild && props.additionalChild}
      </div>
    </div>
  );
};
