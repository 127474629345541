import { formatPhoneNumberIntl } from "react-phone-number-input";
import { getFirstAndLastWord } from "./helper";

export const buildBrowserInfoRequest = function () {
  const date = new Date();

  const request = {
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    screenColorDepth: window.screen.colorDepth,
    navigatorLanguage: navigator.language,
    navigatorJavaEnabled: navigator.javaEnabled(),
    timezoneOffset: date.getTimezoneOffset(),
  };

  return request;
};

export const buildRequest = function (formData, method, amount) {
  let basicCard = null;
  if (method.paymentMethod === "BASIC_CARD" && formData.cardNumber) {
    const expiryDate = formData.expiryDate.split("/");

    if (formData.customerCardId) {
      basicCard = {
        customerCardId: formData.customerCardId,
        cardSecurityCode: formData.cardSecurityCode,
      };
    } else {
      const cardHolderName = getFirstAndLastWord(
        formData.cardholderName.trim()
      );
      basicCard = {
        cardNumber: formData.cardNumber.replace(/\s/g, ""),
        cardholderName: cardHolderName,
        cardSecurityCode: formData.cardSecurityCode,
        expiryMonth: expiryDate[0],
        expiryYear: "20" + expiryDate[1],
        saveCard: formData.saveCard,
        cardPanId: formData.id,
      };
    }
  }

  const browserInfoRequest = buildBrowserInfoRequest();
  const request = {
    paymentMethod: method.paymentMethod,
    amount:
      amount || amount === 0 ? amount : formData.amount.replace(/\s/g, ""),
    customerCitizenshipCountryCode: formData.customerCitizenshipCountryCode,
    billingCountryCode: formData.billingCountryCode,
    customerFirstName: formData.customerFirstName,
    customerLastName: formData.customerLastName,
    billingAddressLine1: formData.billingAddressLine1,
    billingCity: formData.billingCity,
    billingState: formData.billingState,
    billingPostalCode: formData.billingPostalCode,
    customerDateOfBirth: formData.customerDateOfBirth,
    customerEmail: formData.customerEmail,
    customerPhone: formattedPhoneNumber(formData.customerPhone),
    billingAddressLine2: formData.billingAddressLine2,
    customerAccountNumber: formData.customerAccountNumber,
    basicCard: basicCard,
    currencyBlockchain: formData.currencyBlockchain,
    ...browserInfoRequest,
  };

  return request;
};

const formattedPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return null;
  }

  let split = formatPhoneNumberIntl(phoneNumber).trim().split(" ");
  let code = split[0];
  let number = split.slice(1, split.length).join("");
  return code.replace("+", "") + " " + number;
};
