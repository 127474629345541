import React, { useContext } from "react";
import { CheckoutContext } from "../../context/CheckoutContext";
import Assets from "../../assets/Assets";

import "./styles.scss";

export const CustomFooter = () => {
  const { data } = useContext(CheckoutContext);
  return (
    <>
      {data.customFooter && (
        <footer className="custom-footer">
          <div>
            <img
              className="inner-footer-logo"
              src={Assets["payadmit_footer"]}
              alt="Payadmit"
            />
          </div>
          <p className="footer-text">{data.customFooter}</p>
          <hr />
          <p className="footer-bottom-text">
            <span>&#169;</span> 2022. All rights reserved
          </p>
        </footer>
      )}
    </>
  );
};
