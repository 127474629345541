import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Assets from "../../assets/Assets";
import StateAssets from "../../assets/img/state/StateAssets";
import { formatAmount } from "../../utils/CurrencyFormater";
import { CheckoutContext } from "../../context/CheckoutContext";
import { SecureLogos } from "../Components";

import "./styles.scss";

export const State = () => {
  const { data } = useContext(CheckoutContext);
  const { t } = useTranslation();

  let imageSrc;

  switch (data.state) {
    case "COMPLETED":
      imageSrc = StateAssets.success;
      break;
    case "CANCELLED":
      imageSrc = StateAssets.cancelled;
      break;
    case "IN_PROGRESS":
      imageSrc = StateAssets.progress;
      break;
    default:
      imageSrc = StateAssets.cancelled;
  }

  return (
    data.showResultPage && (
      <>
        <div className="wrapper-state">
          <img className="logo" src={Assets["payadmit"]} alt="Payadmit" />
          {data.amount !== 0 && (
            <p className="amount-currency">
              {formatAmount(data.amount, data.currency)}
            </p>
          )}
          <h1 className={`title-state ${data.state}`}>{t(data.state)}</h1>
          {data.errorCode === "TERMINAL_NOT_FOUND" && (
            <p className="errorCode-text">{t(data.errorCode)}</p>
          )}
          <img className="state-image" src={imageSrc} alt={data.state} />
          {data.showBackToShopButton && (
            <a className="link-return-shop" href={data.returnUrl}>
              {t("backToShopButtonLabel")}
            </a>
          )}
          {data.generateReceipt && (
            <a className="receipt-btn" href={data.receiptUrl}>
              {t("receiptBtn")}
            </a>
          )}
        </div>
        <SecureLogos />
      </>
    )
  );
};
