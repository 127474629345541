import React, { useContext } from "react";
import { DispatchContext } from "../../context/CheckoutContext";
import PaymentMethodLogos from "./PaymentMethodLogos";
import { capitalizeAndReplaceUnderscore, getSvgImg } from "../../utils/helper";
import { buildRequest } from "../../utils/RequestHelper";

import "./style.scss";

export const Method = ({ method, amount }) => {
  const { setMethod, postCheckout } = useContext(DispatchContext);

  const checkoutMethod = (method) => {
    if (
      (!method.additionalFields || method.additionalFields.length === 0) &&
      method.paymentMethod !== "BASIC_CARD"
    ) {
      const request = buildRequest({}, method, amount);
      postCheckout(request);
    } else {
      setMethod(method);
    }
  };

  return (
    <div onClick={() => checkoutMethod(method)} className="card-method">
      <input
        className="checkbox-input-method"
        type="radio"
        name="paymentMethod"
        id={method.paymentMethod}
        value={method.paymentMethod}
      />
      <label className="checkbox-label-method" htmlFor={method.paymentMethod}>
        <div className="logo-wrapper">
          {getSvgImg(PaymentMethodLogos[method.paymentMethod.toLowerCase()])}
        </div>
        <span>{capitalizeAndReplaceUnderscore(method.paymentMethod)}</span>
      </label>
    </div>
  );
};
