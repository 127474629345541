import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import "cleave.js/dist/addons/cleave-phone.i18n.js";
import Cleave from "cleave.js/react";
import { useTranslation } from "react-i18next";

export const CleaveInput = (props) => {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className={`wrapper-input ${props.wrapperClass}`}>
      <label htmlFor={props.id}>{t(props.label)}</label>
      <div className={`input-container ${props.specialClass}`}>
        <Controller
          as={
              <Cleave
                options={props.options}
                id={props.id}
                name={props.name}
                placeholder={t(props.placeHolder)}
                type={props.type}
                className={
                  "input-custom " +
                  props.inputClass +
                  (errors[props.name]?.message ? " error " : " ")
                }
                autoComplete={props.autocomplete}
              />
          }
          name={props.name}
          control={control}
          defaultValue=""
          rules={{ validate: props.inputValidation }}
        />
        <img src={props.brand} alt={props.brand} className="card-icon" />
        {(errors[props.name]?.ref.name == "expiryDate" ||
          "cardSecurityCode") && (
          <>
            <div className="error__message-block">
              <p className="error__message error__message--expiryDate">
                {errors[props.name]?.message}
              </p>
            </div>
          </>
        )}
        {props.additionalChild && props.additionalChild}
      </div>
    </div>
  );
};
