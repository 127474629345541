import React, { useState, useContext } from "react";
import { FormProvider } from "react-hook-form";
import {
  CheckoutContext,
  DispatchContext,
} from "../../context/CheckoutContext";
import Assets from "../../assets/Assets";
import {
  CardForm,
  AlternativeForm,
  SecureLogos,
  CustomFooter,
  Timer,
} from "../Components";
import { ReactComponent as BackIcon } from "../../assets/img/form-icons/save.svg";

import "./styles.scss";

export const PaymentForm = ({
  form,
  onSubmit,
  dataCards,
  handleClick,
  handleSelectCard,
  handleDeleteCard,
  isNew,
  checked,
  handleChange,
}) => {
  const { data, method } = useContext(CheckoutContext);
  const { unsetMethod, cancelPayment } = useContext(DispatchContext);
  const [requestAmount] = useState(!data.amount);

  function showMethodForm() {
    switch (method.paymentMethod) {
      case "BASIC_CARD":
        return (
          <CardForm
            requestAmount={requestAmount}
            handleClick={handleClick}
            dataCards={dataCards}
            handleSelectCard={handleSelectCard}
            handleDeleteCard={handleDeleteCard}
            isNew={isNew}
            checked={checked}
            handleChange={handleChange}
          />
        );
      default:
        return <AlternativeForm requestAmount={requestAmount} />;
    }
  }

  const showBackButton = () => {
    if (data.checkoutOptions && data.checkoutOptions.length > 1) {
      return (
        <div className="button-back" onClick={() => unsetMethod()}>
          <BackIcon />
        </div>
      );
    } else {
      return <div className="empty"></div>;
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <div className="navbar">
          {showBackButton()}
          {!data.checkoutTtl && (
            <img
              className="inner-form-logo"
              src={Assets["payadmit"]}
              alt="Payadmit"
            />
          )}
          {data.showCancelButton && (
            <div
              className={`button-cancel ${data.checkoutOptions && data.checkoutOptions.length > 1 ? "left" : ""}`}
              onClick={() => cancelPayment()}
            >
              <img src={Assets.close} alt="cancel" />
            </div>
          )}
        </div>
        {data.checkoutTtl && (
          <div className="logo-timer">
            <img
              className="inner-form-logo"
              src={Assets["payadmit"]}
              alt="Payadmit"
            />
            <Timer />
          </div>
        )}

        {showMethodForm()}
      </form>
      <SecureLogos />
      {method.footerText && (
        <div className="form-footer">{method.footerText}</div>
      )}
      <CustomFooter />
    </FormProvider>
  );
};
