import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SecureLogos } from "../Components";
import Assets from "../../assets/Assets";
import StateAssets from "../../assets/img/state/StateAssets";
import PaymentMethodLogos from "../method/PaymentMethodLogos";
import { ReactComponent as BackIcon } from "../../assets/img/form-icons/save.svg";

import "./styles.scss";
import {
  CheckoutContext,
  DispatchContext,
} from "../../context/CheckoutContext";

export const Cascading = () => {
  const { data, method } = useContext(CheckoutContext);
  const { confirmCascading, unsetMethod, cancelPayment } =
    useContext(DispatchContext);
  const { t } = useTranslation();
  const getSvgImg = (Image) => typeof Image === "object" && <Image />;

  const [useTimeOut, setUseTimeout] = useState(
    data.hasOwnProperty("cascadingConfirmationTimeout")
  );

  const [count, setCount] = useState(data.cascadingConfirmationTimeout);

  useEffect(() => {
    if (useTimeOut) {
      const i_id = setTimeout(() => {
        setCount((currCount) => currCount - 1);
        if (count === 0) {
          confirmCascading();
        }
      }, 1000);
    }
  }, [count, useTimeOut]);

  const showBackButton = () => {
    if (data.checkoutOptions && data.checkoutOptions.length > 1) {
      return (
        <div className="button-back" onClick={() => unsetMethod()}>
          <BackIcon />
        </div>
      );
    } else {
      return <div className="empty"></div>;
    }
  };

  return (
    <>
      <div className="wrapper-state">
        <div className="navbar">
          {showBackButton()}
          <img
            className="inner-form-logo"
            src={Assets["payadmit"]}
            alt="Payadmit"
            style={{ marginTop: "0px" }}
          />
          {data.showCancelButton && (
            <div className="button-cancel" onClick={() => cancelPayment()}>
              <img src={Assets.close} alt="cancel" />
            </div>
          )}
        </div>
        {method && (
          <div className="wrapper-payment-method">
            <span className="method-label">
              {typeof method === "object" ? t(method.paymentMethod) : t(method)}
            </span>
            <div className="logo-wrapper">
              {getSvgImg(PaymentMethodLogos[method.toLowerCase()])}
            </div>
          </div>
        )}
        <div className="cascading-instructions">
          {
            "Your transaction was declined, but don't worry. \n Please confirm to continue with another provider. \n You do not need to fill out the form again"
          }
        </div>
        <img
          className="state-image-cascading"
          src={StateAssets.cascading_confirmation}
          alt={data.state}
        />
        {useTimeOut ? (
          <input
            className="button-submit"
            style={{ width: "100%" }}
            type="button"
            onClick={() => confirmCascading()}
            value={t("ButtonConfirmCascadingTimeoutLabel") + " " + count}
          />
        ) : (
          <input
            className="button-submit"
            style={{ width: "100%" }}
            type="button"
            onClick={() => confirmCascading()}
            value={t("ButtonConfirmCascadingLabel")}
          />
        )}
      </div>
      <SecureLogos />
    </>
  );
};
